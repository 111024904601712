import {AlertService, EmailIntegrationBaseService} from '../_services';
import {ActivatedRoute, Router} from '@angular/router';
import {OnInit} from '@angular/core';


export class AuthCallbackAbstractComponent implements OnInit {
  public campaignId: string | number;
  public integrationType: number;
  public loading = false;
  constructor(
    protected emailIntegrationService: EmailIntegrationBaseService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params) {
        if (!('state' in params)) {
          this.alertService.error('Invalid parameters provided', true);
          this.router.navigate(['/']);
          return;
        }
        const state = this.extractState(params['state']);
        if (!('campaign' in state) || !('type' in state)) {
          this.alertService.error('Invalid parameters provided', true);
          this.router.navigate(['/']);
          return;
        } else {
          this.campaignId = parseInt(state['campaign'], 10);
          this.integrationType = parseInt(state['type'], 10);
          this.emailIntegrationService.setCampaignId(this.campaignId);
        }
        if ('error' in params) {
          if (params['error'] === 'access_denied') {
            this.alertService.error('Please allow solvpath permissions to create integration', true);
          } else {
            this.alertService.error('An error occured with authorizing your request. code: ' + params['error'], true);
          }
          this.router.navigate(['/email-integration', `${this.campaignId}`, 'select']);
          return;
        }
        if (this.validateAdditionalData(params)) {
          this.createIntegration(params);
        }
      }
    });
  }

  extractState(state: string) {
    const states = {};
    state.split('&').forEach((eachState: string) => {
      const splittedState = eachState.split('=');
      states[splittedState[0]] = splittedState[1];
    });
    return states;
  }

  validateAdditionalData(params: {}) {
    return true;
  }

  getAuthDataForCreation(params: {}) {
    return {};
  }

  createIntegration(params: {}) {
    this.loading = true;
    const data = {type: this.integrationType, ...this.getAuthDataForCreation(params)};
    this.emailIntegrationService.create(data)
      .subscribe((_) => {
        this.loading = false;
        this.alertService.success('Email integration has been successfully created!', true);
        this.router.navigate(['/campaign', 'email', `${this.campaignId}`]);
      }, error => {
        this.loading = false;
        this.alertService.error(error.error, true);
        this.router.navigate(['/email-integration', `${this.campaignId}`, 'select']);
      });
  }
}
