import {Component, OnInit} from '@angular/core';
import {CrudSaveComponent} from '../_directives';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, EmailIntegrationService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {EmailIntegration, EmailIntegrationStatus, EmailIntegrationType} from '../_models';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-forwarding-integration',
  templateUrl: './email-integration-forwarding-edit.component.html'
})
export class EmailIntegrationForwardingNewComponent extends CrudSaveComponent implements OnInit {
  public campaignId: string | number;
  public emailIntegration: EmailIntegration;
  public readonly environment = environment;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected emailIntegrationService: EmailIntegrationService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder
  ) {
    super(router, location, route, emailIntegrationService, alertService);
    this.isNew = true;
    this.title = 'New Email Integration';
    this.objectName = 'email integration';
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      type: [EmailIntegrationType.Forwarding],
    });
    super.ngOnInit();
  }

  protected onSaveComplete(data: EmailIntegration) {
    this.loading = false;
    this.router.navigate(['/email-integration', this.campaignId, 'forwarding', 'edit', data.id]);
  }

  protected onRouteParams(params: {}) {
    this.campaignId = params['campaign'];
    this.emailIntegrationService.setCampaignId(this.campaignId);
  }

  canVerifyIntegration() {
    return this.emailIntegration && this.emailIntegration.status === EmailIntegrationStatus.Unverified;
  }

  canSPFVerify() {
    return this.emailIntegration && this.emailIntegration.status === EmailIntegrationStatus.VerifiedDomainOnly;
  }

  verify() {
    if (this.emailIntegration) {
      this.emailIntegrationService.verify(this.emailIntegration.id, {})
        .subscribe(_ => {
          this.emailIntegration.status = EmailIntegrationStatus.VerifiedDomainOnly;
        }, error => {
          this.handleError(error);
        });
    }
  }

  verifySPF() {
    if (this.emailIntegration) {
      this.emailIntegrationService.verifSPF(this.emailIntegration.id)
        .subscribe(_ => {
          this.emailIntegration.status = EmailIntegrationStatus.Verified;
        }, error => {
          this.handleError(error);
        });
    }
  }
}
