import {Component} from '@angular/core';
import {AuthCallbackAbstractComponent} from './auth-callback-abstract.component';
import {AlertService, EmailIntegrationService, GmailService} from '../_services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  templateUrl: './auth-callback.component.html'
})
export class GmailAuthCallbackComponent extends AuthCallbackAbstractComponent {
  constructor(
    protected emailIntegrationService: GmailService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alertService: AlertService
  ) {
    super(emailIntegrationService, route, router, alertService);
  }

  validateAdditionalData(params: {}) {
    if (!('scope' in params) || !('code' in params)) {
      this.alertService.error('Invalid parameters provided', true);
      this.router.navigate(['/email-integration', `${this.campaignId}`, 'select']);
      return false;
    }
    return true;
  }

  getAuthDataForCreation(params: {}) {
    const data = {};
    data['token'] = params['code'];
    return data;
  }
}
