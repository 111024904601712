import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment-timezone';
import { steps } from '../../step-data';
import {
  Breadcrumb,
  OfferTypeLabels,
  OfferTypes,
  StepDateTypes,
  StepPopupActions,
  StepStatusLabels,
  StepStatuses,
  CampaignProduct,
  StepCategoryLabels,
  User,
} from '../../../_models';
import {
  AlertService,
  CampaignService,
  FunnelInputService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  UserService,
} from '../../../_services';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getTruncatedProductName } from '../../step-data';
import { Form } from '../../../_forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent extends Form implements OnInit {
  steps = steps;
  isTableVisible: boolean = true;
  showThreeDotMenu: boolean = false;
  offer: any;
  offerId: number;
  offerType: OfferTypes;
  offerName: string;
  selectedDate: StepDateTypes = StepDateTypes.LastModified;
  tableDateTypes = StepDateTypes;
  tableData: any[];
  originalTableData: any[];
  offerSteps: any[];
  originalOfferSteps: any[];
  offerPreviewsLoading: boolean;
  hoveredStepIndex: string | null = null;
  StepPopupActions = StepPopupActions;
  popupTitle: string = '';
  breadcrumbs: Breadcrumb[] = [];
  popupData = {
    name: '',
    is_survey: false,
    offer_id: null,
    step_id: null,
    is_step: true,
    offer_intent: null,
    offer_type: null,
    popupType: null,
    step_response: {},
  };
  stepStatuses = StepStatuses;
  stepStatusLabels = StepStatusLabels;
  stepPopupTitleMap = {
    [StepPopupActions.Update]: 'Rename Step',
    [StepPopupActions.Duplicate]: 'Duplicate Step',
    [StepPopupActions.Delete]: 'Delete Step',
  };
  selectedBrandId: string | number = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  campaigns: any[] = null;
  campaignsLoading: boolean = true;
  statuses = [
    { value: StepStatuses.All, label: StepStatusLabels[StepStatuses.All] },
    { value: StepStatuses.Enabled, label: StepStatusLabels[StepStatuses.Enabled] },
    { value: StepStatuses.Disabled, label: StepStatusLabels[StepStatuses.Disabled] },
  ];
  selectedStatus = StepStatuses.All;
  protected _destroy$: Subject<boolean> = new Subject<boolean>();
  public user: User;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private campaignService: CampaignService,
    public ngxSmartModalService: NgxSmartModalService,
    public loader: LoaderService,
    protected alertService: AlertService,
    private sanitizer: DomSanitizer,
    protected inputService: FunnelInputService,
    protected userService: UserService,
    protected location: Location
  ) {
    super(alertService, router, location);
    this.route.params.subscribe((params) => {
      this.offerId = params['offer_id'];
      this.offerType = Number(params['offer_type']);
      this.offerName = OfferTypeLabels[this.offerType];
    });
  }

  ngOnInit() {
    this.funnelStepService.setFunnelId(this.offerId);
    this.generateBreadcrumbs();
    this.getCampaigns();
    this.fetchOfferSteps();

    this.userService.getCurrent().subscribe(
      (user: User) => {
        this.user = user;
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  fetchOfferSteps() {
    this.offerPreviewsLoading = true;
    this.loader.show();
    this.funnelService.get(this.offerId).subscribe(
      (response: any) => {
        if (response) {
          this.offer = response;
          this.breadcrumbs[1].label = response.slug;

          this.funnelStepService.getOfferSteps().subscribe(
            (response) => {
              if (response.length) {
                this.offerSteps = response;
                this.offerSteps.forEach((step) => {
                  step.loading = true;
                });
                this.populateTableData();
                this.getPreviews();
              } else {
                this.offerSteps = [];
                this.loader.hide();
              }
            },
            (error) => {
              this.offerPreviewsLoading = false;
              this.loader.hide();
              this.alertService.error(error);
            }
          );
        }
      },
      (error) => {
        this.loader.hide();
        this.offerPreviewsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  populateTableData() {
    this.tableData = this.offerSteps.map((item: any) => {
      this.loader.hide();
      const lastModifiedMoment = moment(item.last_modified).tz('EST');
      const lastModifiedObject = {
        date: lastModifiedMoment.format('DD MMM, YYYY'),
        time: lastModifiedMoment.format('HH:mm:ss z'),
      };

      const stepInput = this.getStepInput(item.id);

      return {
        id: item.id,
        stepName: item.name,
        status: stepInput.enabled
          ? StepStatuses.Enabled
          : !stepInput.enabled
          ? StepStatuses.Disabled
          : StepStatuses.Draft,
        type: StepCategoryLabels[item.category],
        stepInput: stepInput,
        lastModified: lastModifiedObject,
        visitors: item.visitors || 0,
        category: item.category,
        clicks: item.clicks || 0,
        takeRates: item.clicks && item.visitors ? (item.clicks / item.visitors) * 100 : 0,
        averageTime: item.averageTime || '--', // not getting this from backend
        usages: item.path_names.length
          ? `${item.path_names.length} ${item.path_names.length > 1 ? 'Paths' : 'Path'}`
          : '0 Paths',
        displayId: item.display_id,
      };
    });
    this.originalTableData = this.tableData;
  }

  onStatusChange(event: any): void {
    const status = +event.target.value;
    if (status === StepStatuses.All) {
      this.tableData = [...this.originalTableData];
      this.offerSteps = [...this.originalOfferSteps];
    } else {
      this.tableData = this.originalTableData.filter((item) => item.status === status);
      this.offerSteps = this.originalOfferSteps.filter((step) => this.tableData.find((item) => item.id === step.id));
    }
  }

  getPreviews() {
    let completedRequests = 0;
    const totalSteps = this.offerSteps.length;
    const productId = (this.selectedProduct && this.selectedProduct.id) || null;
    this.offerPreviewsLoading = true;

    this.offerSteps.forEach((step) => {
      const steps = this.offer.steps.filter((offer_step) => offer_step.id === step.id);

      this.funnelStepService.preview(this.selectedBrandId, steps[0], productId).subscribe(
        (data: string) => {
          step.sanitizedUrl = this.getSanitizedUrl(data);
          step.url = data;
          step.loading = false;
          completedRequests++;
          if (completedRequests === totalSteps) {
            this.offerPreviewsLoading = false;
          }
        },
        (error) => {
          this.alertService.error(error);
          step.loading = false;
        }
      );
    });
    this.originalOfferSteps = this.offerSteps;
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data) => {
        this.campaigns = data.results;
        this.selectedBrandId = this.campaigns[0].id;
        this.campaignsLoading = false;
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  updateBrand() {
    this.getPreviews();
  }

  updateProduct() {
    this.getPreviews();
  }

  toggleStepStatus(stepInputId: number, hasInputStep: boolean = false, isEnabled?: boolean): void {
    if (!hasInputStep) {
      const stepInput = this.getStepInput(stepInputId);
      this.toggleStatus(stepInput.id, stepInput.enabled);
    } else {
      this.toggleStatus(stepInputId, isEnabled);
    }
  }

  toggleStatus(id: number, currentStatus: boolean): void {
    this.inputService.patch(id, { enabled: !currentStatus }).subscribe(
      () => {
        this.fetchOfferSteps();
        this.alertService.success('Step status updated successfully', true);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  isStepEnabled(stepId: number): boolean {
    const stepInput = this.getStepInput(stepId);
    return stepInput.enabled;
  }

  getStepInput(stepId: number) {
    const offerStep = this.offer.offer_steps.find(({ id }) => id === stepId);
    if (offerStep) {
      return offerStep.inputs.find(({ has_response_step }) => has_response_step);
    }
  }

  generateBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1) + ' Offers',
        url: ['steps', this.offerName],
      },
      {
        label: '',
      },
    ];
  }

  openPopup(popupType: number, stepId?: number | string, stepName?: string): void {
    this.popupTitle = this.stepPopupTitleMap[popupType] || '';
    const stepResponse = this.offerSteps.find((step) => step.id === stepId);
    this.popupData = {
      ...this.popupData,
      name: stepName || '',
      step_id: stepId || null,
      offer_id: this.offerId || null,
      step_response: stepResponse || {},
      popupType: popupType,
    };

    if (popupType === StepPopupActions.Duplicate) {
      this.popupData['parent_input'] = this.getParentInputForNewStep();
    }
    this.ngxSmartModalService.getModal('stepPopup').open();
  }

  getParentInputForNewStep() {
    let parent_input = null;
    if (this.offer.offer_steps && this.offer.offer_steps.length > 0) {
      const lastStepIndex = this.offer.offer_steps.length - 1;
      if (this.offer.offer_steps[lastStepIndex].inputs && this.offer.offer_steps[lastStepIndex].inputs.length > 0) {
        this.offer.offer_steps[lastStepIndex].inputs.forEach((input) => {
          if (!input.has_response_step) {
            parent_input = input.id;
          }
        });
      }
    }

    return parent_input;
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  saveStep(): void {
    this.loader.show();
    this.fetchOfferSteps();
    this.ngxSmartModalService.getModal('stepPopup').close();
  }

  saveTemplate(event: { use_existing_template: boolean }): void {
    this.ngxSmartModalService.getModal('templatePopup').close();
    if (this.offerType === OfferTypes.Downsell) {
      this.router.navigate([
        'steps',
        this.offerName,
        this.popupData.offer_id,
        'category',
        this.offerType,
        'intent',
        this.popupData.offer_intent,
        'useExisting',
        event.use_existing_template,
      ]);
    } else {
      this.router.navigate([
        'steps',
        this.offerName,
        this.popupData.offer_id,
        'category',
        this.offerType,
        'useExisting',
        event.use_existing_template,
      ]);
    }
  }

  openTemplatePopup() {
    this.popupData = {
      ...this.popupData,
      offer_id: this.offerId,
      offer_intent: this.offerType,
      popupType: StepPopupActions.CreateTemplate,
    };
    this.ngxSmartModalService.getModal('templatePopup').open();
  }

  closeTemplatePopup() {
    this.ngxSmartModalService.getModal('templatePopup').close();
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal('selectProductsDialog').open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.getPreviews();
    }
    this.ngxSmartModalService.getModal('selectProductsDialog').close();
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  navigateToStepBuilder(category: number, stepId: number) {
    this.router.navigate(['steps', this.offerName, this.offerId, 'category', this.offerType, category, 'step', stepId]);
  }

  openPreview(stepId: number) {
    const step = this.offerSteps.find((step) => step.id === stepId);
    if (step && step.url) {
      window.open(step.url, '_blank');
    } else {
      this.alertService.error('Please wait while we generate the preview link.');
    }
  }

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  closeOutside() {
    if (this.tableData) {
      this.tableData.forEach((item) => {
        item.showThreeDotMenu = false;
      });
    }
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }

  setHoveredStep(id: string) {
    this.hoveredStepIndex = id;
  }
  clearHoveredStep() {
    this.hoveredStepIndex = null;
  }

  navigateToStepTemplates() {
    if (this.offerType === OfferTypes.Downsell)
      this.router.navigate([
        'steps',
        this.offerName,
        this.offerId,
        'category',
        this.offerType,
        'intent',
        this.offer.offer_intent,
      ]);
    else this.router.navigate(['steps', this.offerName, this.offerId, 'category', this.offerType]);
  }

  private getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get destroy$() {
    return this._destroy$.asObservable();
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [StepPopupActions.Update]: '/assets/stepAssets/rename.svg',
  };

  convertToTemplate(stepId: string | number, is_global: boolean = false) {
    this.loader.show();
    this.funnelStepService.convertToTemplate(stepId, is_global).subscribe(
      (_) => {
        this.loader.hide();
        this.alertService.success('Step converted to template successfully');
      },
      (error) => {
        this.loader.hide();
        this.handleError(error);
      }
    );
  }
}
