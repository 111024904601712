import {BaseModel} from './base-model';

export enum EmailIntegrationType {
  Forwarding = 1,
  Gmail = 2
}

export const EmailIntegrationTypeLabels = {};
EmailIntegrationTypeLabels[EmailIntegrationType.Forwarding] = 'Forwarding';
EmailIntegrationTypeLabels[EmailIntegrationType.Gmail] = 'Gmail';

export enum EmailIntegrationStatus {
  Unverified = 0,
  VerifiedDomainOnly = 1,
  Verified = 2
}

export const EmailIntegrationStatusLabels = {};
EmailIntegrationStatusLabels[EmailIntegrationStatus.Unverified] = 'Awaiting Forwarding Verification';
EmailIntegrationStatusLabels[EmailIntegrationStatus.VerifiedDomainOnly] = 'Awaiting SPF verification';
EmailIntegrationStatusLabels[EmailIntegrationStatus.Verified] = 'Verified';

export interface EmailIntegration extends BaseModel {
  name: string;
  type: EmailIntegrationType;
  status: EmailIntegrationStatus;
  forwarding_email: string;
  email: string;
  auth_link: string;
}
