import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  StepMedia,
  StepButtons,
  StepResponseScreen,
  StepTextual,
  StepProductsPreview,
  StepElements,
  StepResponseMedia,
  StepMediaPanels,
} from '../_models/steps';
import { cloneDeep } from 'lodash';
import {
  StepButtonsInitialData,
  StepMediaInitialData,
  StepProductPreviewInitialData,
  StepResponseMediaInitialData,
  StepResponseScreenInitialData,
  StepTextualInitialData,
} from '../steps-library/step-data';

@Injectable({
  providedIn: 'root',
})
export class StepEmulatorService {
  private stepTextualSubject = new BehaviorSubject<StepTextual>(cloneDeep(StepTextualInitialData));
  stepTextual$: Observable<StepTextual> = this.stepTextualSubject.asObservable();

  private stepMediaSubject = new BehaviorSubject<StepMedia>(cloneDeep(StepMediaInitialData));
  stepMedia$: Observable<StepMedia> = this.stepMediaSubject.asObservable();

  private stepResponseMediaSubject = new BehaviorSubject<StepResponseMedia>(cloneDeep(StepResponseMediaInitialData));
  stepResponseMedia$: Observable<StepResponseMedia> = this.stepResponseMediaSubject.asObservable();

  private stepButtonsSubject = new BehaviorSubject<StepButtons>(cloneDeep(StepButtonsInitialData));
  stepButtons$: Observable<StepButtons> = this.stepButtonsSubject.asObservable();

  private stepResponseScreenSubject = new BehaviorSubject<StepResponseScreen>(cloneDeep(StepResponseScreenInitialData));
  stepResponseScreen$: Observable<StepResponseScreen> = this.stepResponseScreenSubject.asObservable();

  private stepProductsPreviewSubject = new BehaviorSubject<StepProductsPreview>(
    cloneDeep(StepProductPreviewInitialData)
  );
  stepProductsPreview$: Observable<StepProductsPreview> = this.stepProductsPreviewSubject.asObservable();

  private elementStates: { [key in StepElements]: boolean } = {
    //Elements with editors are initialized as true here
    [StepElements.Banner]: true,
    [StepElements.Body]: true,
    [StepElements.Media]: false,
    [StepElements.Buttons]: true,
    [StepElements.Response]: true,
    [StepElements.Discount]: false,
    [StepElements.DiscountSettings]: false,
    [StepElements.Exchangeables]: false,
    [StepElements.Actions]: false,
    [StepElements.SurveyOptions]: false,
    [StepElements.TimeDelay]: false,
    [StepElements.TimeOptions]: false,
  };

  selectedMediaPanel: StepMediaPanels = StepMediaPanels.Step;

  constructor() {}

  toggleElement(element: StepElements): void {
    const state = this.elementStates[element];
    this.hideAllElements();
    this.elementStates[element] = !state;
  }

  isElementVisible(element: StepElements): boolean {
    return this.elementStates[element];
  }

  hideAllElements(): void {
    Object.keys(this.elementStates).forEach((key) => {
      this.elementStates[key as StepElements] = false;
    });
  }

  updateStepTextual(data: StepTextual) {
    this.stepTextualSubject.next(data);
  }

  updateStepMedia(data: StepMedia) {
    this.stepMediaSubject.next(data);
  }

  updateResponseMedia(data: StepResponseMedia) {
    this.stepResponseMediaSubject.next(data);
  }

  updateStepButtons(data: StepButtons) {
    this.stepButtonsSubject.next(data);
  }

  updateStepResponseScreen(data: StepResponseScreen) {
    this.stepResponseScreenSubject.next(data);
  }

  updatePreviewProducts(data: StepProductsPreview) {
    this.stepProductsPreviewSubject.next(data);
  }

  resetStepData() {
    this.stepMediaSubject.next(cloneDeep(StepMediaInitialData));
    this.stepResponseMediaSubject.next(cloneDeep(StepResponseMediaInitialData));
    this.stepButtonsSubject.next(cloneDeep(StepButtonsInitialData));
    this.stepTextualSubject.next(cloneDeep(StepTextualInitialData));
    this.stepResponseScreenSubject.next(cloneDeep(StepResponseScreenInitialData));
    this.stepProductsPreviewSubject.next(cloneDeep(StepProductPreviewInitialData));
  }
}
