import {Component, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Prism from 'prismjs';
import 'prismjs/components/prism-ruby.min.js';
import 'prismjs/components/prism-python.min.js';
import 'prismjs/components/prism-bash.min.js';
import 'prismjs/components/prism-java.min.js';
import 'prismjs/components/prism-json.min.js';
import 'prismjs/components/prism-markup.min.js';
import {Campaign, CustomDomain, DeploymentLink} from '../_models';

@Component({
  selector: 'app-iframe-documentation',
  templateUrl: './iframe-documentation.component.html',
  styleUrls: ['./iframe-documentation.component.css']
})
export class IframeDocumentationComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input('brand') brand: Campaign;
  @Input('domain') domain: CustomDomain;
  @Input('link') link: DeploymentLink;

  private highlightInterval: any;
  selectedLanguage: string = 'Python'; // Default to Python
  responseUrl: string = '';
  examplePayload = {
    "customer_id": '"customer_id": 1234'
  }

  payloadFields = `
    "customer_id": "string",      // CRM Customer ID. Example: "1234"
    "email": "string",            // Customer's email address
    "order_number": "string",     // CRM Order ID. Example: "12345678"
    "product_id": "string",       // CRM Product ID of the order's line item. Example: "12"
    "step": "string",             // Path's step to start the session on. Examples: "slug", "select-your-item", "faqs"
    "tracking_source": "integer", // Tracking Source ID. Example: 1
    "sid1": "string",             // Tracking Sub ID 1. Example: "sub1"
    "sid2": "string",             // Tracking Sub ID 2. Example: "sub2"
    "sid3": "string"              // Tracking Sub ID 3. Example: "sub3"
  `

  constructor(
    @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit() {
    this.highlightInterval = setInterval(() => {
      Prism.highlightAll();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.brand) {
      this.generateUrl()
    }
  }

  openLanguage(language: string): void {
    this.selectedLanguage = language;
    setTimeout(() => {
      Prism.highlightAll();
    }, 0);
  }

  scrollToElementInModal(elementId: string) {
    const element = this.document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy() {
    if (this.highlightInterval) {
      clearInterval(this.highlightInterval); // Clear the interval on component destruction
    }
  }

  get apiPath() {
    if (this.domain && this.domain.id) {
      return 'custom_domains/' + this.domain.id.toString() + '/start_session/';
    }
    if (this.brand) {
      return 'campaigns/' + this.brand.id.toString() + '/start_session/';
    }
  }

  get examplePayloadValues() {
    let payload = ``
    const values = Object.values(this.examplePayload)
    for(let index in values) {
      const isLast = +index === (values.length - 1)
      payload += `  ${values[index]}${isLast ? '' : ','}`
      if (this.selectedLanguage !== 'Java') {
        payload += `\n`;
      }
    }
    return payload
  }

  generateUrl() {
    let url = `https://${this.domain ? this.domain.domain : this.brand.active_domain}/start/sEsSIoN-123456789abcd/`;

    if (this.link) {
      if (this.link.step_key) {
        url += this.link.step_key && this.link.step_key;
        this.examplePayload["step"] = `"step": "${this.link.step_key}"`
      }

      let separator = '?';
      if (this.link.tracking_source) {
        url += separator + 'tracking_source=' + this.link.tracking_source['id'];
        this.examplePayload["tracking_source"] = `"tracking_source": "${this.link.tracking_source['id']}"`
        separator = '&';
      }

      ['sid1', 'sid2', 'sid3'].forEach((key: string) => {
        let value: string = this.link[key];
        if (value && value.length) {
          value = value.trim();
          if (value.length) {
            url += separator + key + '=' + value.toLowerCase();
            this.examplePayload[`"${key}"`] = `"${key}": "${value.toLowerCase()}"`
          }
        }
      });
    } else {
      url += 'enter_product_funnel';
    }

    this.responseUrl = url
  }
}
