import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignDomainService, FunnelService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {FormBuilder} from '@angular/forms';
import {
  CustomDomain,
  Campaign,
  Pager,
  Funnel,
} from "../_models";
import {config} from "../../config/config";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-domain-funnels',
  templateUrl: './campaign-domain-funnels.component.html'
})
export class CampaignDomainFunnelsComponent extends CrudSavePopupComponent implements OnInit {
  domain: CustomDomain;
  errors: string[] = [];
  @Input('campaign') campaign: Campaign;
  ready = false;
  assignedFunnels: Funnel[] = [];
  funnels: Funnel[] = [];

  constructor(protected router: Router,
              protected location: Location,
              protected route: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected domainService: CampaignDomainService,
              protected alertService: AlertService,
              private funnelService: FunnelService
  ) {
    super(router, location, route, domainService, alertService);
    this.objectName = 'Custom Domain';
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      campaign: [this.campaign && this.campaign.id]
    }));

    super.ngOnInit();
  }

  protected onInputDataChanged() {
    this.domain = this.inputData;
    this.isNew = !this.domain || !this.domain.id;
    this.errors = [];
    let selectedFunnelMap = {};
    this.funnels = [];
    this.assignedFunnels = [];
    const funnels = [];
    const assignedFunnels = [];

    if (this.domain) {
      const funnelIds = this.domain.visual_entry_funnels || [];

      for (let funnel_id of funnelIds) {
        selectedFunnelMap[funnel_id] = true;
      }

      this.funnelService.list(
        {page: 1, page_size: config.maxPageSize, resourcetype: 'VisualFunnel', session_type: this.domain.type}
      ).subscribe(
        (data: Pager) => {
          if (data) {
            for (let funnel of data.results) {
              funnels.push(funnel);

              if (funnel.id in selectedFunnelMap) {
                assignedFunnels.push(funnel);
              }
            }
          }

          this.funnels = funnels;
          this.assignedFunnels = assignedFunnels;
          this.ready = true;
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  close() {
    this.errors = [];
    this.onCancel.emit();
  }

  getFunnelLabel(funnel: Funnel): string {
    return funnel.is_template ? 'System Template - ' + funnel.name : funnel.name;
  }

  protected getFormData() {
    const key = 'visual_entry_funnels';
    let funnelIds = {};
    funnelIds[key] = [];

    this.assignedFunnels.forEach((funnel: Funnel) => {
      funnelIds[key].push(funnel.id);
    });

    return Object.assign({}, this.form.value, funnelIds);
  }

  protected onPostSaveComplete(data) {
    this.loading = false;
    this.domain = data;
    this.errors = [];
    this.onSave.emit(data);
  }

}
