import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignTrackingService, FunnelStepService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {Campaign, FunnelStep, TrackingSource, CustomDomain, TicketSystemIDVariables, SessionTypeLabels} from '../_models';
import {NgxSmartModalService} from "ngx-smart-modal";
import {takeUntil} from "rxjs/operators";
import {DeploymentLinkService} from '../_services';
import {CrudSavePopupComponent} from '../_directives';
import {copyToClipboard} from '../_helpers';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-links.component.html',
  selector: 'campaign-links',
  styleUrls: ['./campaign-edit.component.css']
})
export class CampaignLinksComponent extends CrudSavePopupComponent implements OnInit, OnChanges {
  trackingSources: TrackingSource[] = [];
  steps: FunnelStep[] = [];
  selectedSource: TrackingSource;
  generatedUrl = '';
  selectedDomain: CustomDomain = null;
  hideAdditionalParams: boolean = false;
  @Input('campaign') campaign: Campaign;
  @Input('domain') domain: CustomDomain;

  copyValue = copyToClipboard;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private trackingService: CampaignTrackingService,
    private stepService: FunnelStepService,
    private modalService: NgxSmartModalService,
    private deploymentLinkService: DeploymentLinkService,
  ) {
    super(router, location, route, deploymentLinkService, alertService);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      id: [null],
      sid1: [null],
      sid2: [null],
      tracking_source: [null],
      include_ticket_id: [false, null],
      name: [null, Validators.required],
      step_key: [null, Validators.required],
      domain: [{value: this.domain && this.domain.id, disabled: !!this.domain}, Validators.required],
      campaign: [this.campaign && this.campaign.id, Validators.required],
    }));

    super.ngOnInit();
    this.updateForm();

    this.form.valueChanges.subscribe(() => this.generateUrl())
  }

  ngOnChanges() {
    this.updateForm();
  }

  getDomainType(domain: CustomDomain) {
    return SessionTypeLabels[domain.type];
  }

  addNewSubId() {
    this.form.addControl('sid3', this.formBuilder.control(null));
  }

  protected updateForm() {
    if (this.form) {
      this.resetForm();
      this.trackingSources = [];

      if (this.campaign) {
        this.generatedUrl = this.domain ? this.campaignUrl : '';
        this.trackingService.list({campaign: this.campaign.id})
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            sources => {
              this.trackingSources = sources;
            },
            error => {
              this.handleError(error);
            }
          );

        this.stepService.getGlobalJumpSteps(false, {
          resourcetype: 'VisualFunnel',
          funnel__campaigns: this.campaign.id
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (steps: FunnelStep[]) => {
              this.steps = steps;
            },
            error => {
              this.handleError(error);
            }
          );
      }
    }

    super.updateForm();

    if (this.inputData) {
      this.form.patchValue({
        domain: this.inputData.domain['id'] || this.inputData.domain,
        tracking_source: this.inputData.tracking_source && (this.inputData.tracking_source['id'] || this.inputData.tracking_source),
      })
    } else if (this.domain) {
      this.form.get('domain').patchValue(this.domain.id)
    }

  }

  get campaignUrl() {
    const active_domain = this.domain ? this.domain.domain : this.campaign.active_domain;

    return 'https://' + active_domain + '/';
  }

  get hasSubId3() {
    return this.form && this.form.get('sid3')
  }

  onChangeDomain(event) {
    this.selectedDomain = this.campaign.custom_domains[event.currentTarget.selectedIndex - 1];
    this.generateUrl();
  }

  createTrackingSource() {
    this.selectedSource = {
      id: null,
      campaign: this.campaign.id,
      source: null
    };
    this.openEditTrackingSourceDialog();
  }

  editTrackingSource(sourceId: number | string) {
    this.trackingSources.some((source: TrackingSource) => {
      if (sourceId.toString() === source.id.toString()) {
        this.selectedSource = source;
        this.openEditTrackingSourceDialog();

        return true;
      }
    });
  }

  private openEditTrackingSourceDialog() {
    this.modalService.getModal('editTrackingSourceDialog').open();
  }

  onSaveTrackingSource(savedSource: TrackingSource) {
    let isNew = true;

    this.modalService.getModal('editTrackingSourceDialog').close();

    if (!this.trackingSources.some((source: TrackingSource, index: number) => {
      if (savedSource.id === source.id) {
        //existing source, update our local value
        this.trackingSources[index] = savedSource;
        return true;
      }
    })) {
      // new source, add it to our list and select it
      this.trackingSources.push(savedSource);
      this.form.patchValue({tracking_source: savedSource.id})
    }

    // re-sort the sources list
    this.trackingSources.sort((a, b): number => {
      if (a.source < b.source) return -1;
      if (a.source > b.source) return 1;
      return 0;
    });
  }

  generateUrl() {
    let url = this.selectedDomain ? 'https://' + this.selectedDomain.domain + '/' : this.campaignUrl;

    if (this.form.value.step_key && this.form.value.step_key.length) {
      url += 'start/' + encodeURIComponent(this.form.value.step_key);
    }

    let separator = '?';

    if (this.form.value.tracking_source) {
      url += separator + 'tracking_source=' + this.form.value.tracking_source.toString();
      separator = '&';
    }

    if (this.form.value.include_ticket_id && this.campaign.ticket_system && this.campaign.ticket_system.type) {
      url += separator + 'ticket_id=' + TicketSystemIDVariables[this.campaign.ticket_system.type];
      separator = '&'
    }

    ['sid1', 'sid2', 'sid3'].forEach((key: string) => {
      let value: string = this.form.value[key];

      if (value && value.length) {
        value = value.trim();

        if (value.length) {
          url += separator + key + '=' + encodeURIComponent(value.trim().toLowerCase());
          separator = '&';
        }
      }
    });

    this.generatedUrl = url;
  }

  protected onSaveComplete(data) {
    this.cancel();
    super.onSaveComplete(data);
  }

}
