import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, CancelReasonCategoriesService, FunnelStepService} from '../../_services';
import { CrudSavePopupComponent } from '../../_directives';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { StepPopupActions } from '../../_models';

@Component({
  moduleId: module.id.toString(),
  selector: 'template-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./../steps.component.scss'],
})
export class TemplatePopupComponent extends CrudSavePopupComponent implements OnInit {
  ngModelOptions = { standalone: true };
  operation: Observable<Object>;
  StepPopupActions = StepPopupActions;
  title = 'template';
  useExistingTemplate: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected funnelStepService: FunnelStepService,
    protected cancelReasonCategoriesService: CancelReasonCategoriesService,
    protected alertService: AlertService
  ) {
    super(router, location, route, funnelStepService, alertService);
    this.objectName = 'Offer';
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [this.inputData.name || '', Validators.required],
        category: this.inputData.category,
        is_global: false,
        offer_intent: [null],
      })
    );
    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;
    this.onSave.emit({ use_existing_template: this.useExistingTemplate });
  }

  performOperation(operationTitle) {
    this.operation.subscribe(
      (data) => {
        if (this.showSuccessMessage) {
          this.alertService.success(`${operationTitle} Successfully.`, true);
        }

        this.canDeactivateService.setFormDirty(false);
        this.onSaveComplete(data);
      },
      (error) => {
        this.handleSubmitError(error);
        this.loading = false;
      }
    );
  }

  protected getFormData() {
    const data = {
      ...this.form.value,
      popupType: this.inputData.popupType,
      offer_id: +this.inputData.offer_id,
      offer_intent: this.inputData.offer_intent,
      is_survey: this.inputData.is_survey,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.CreateTemplate]: 'Next',
  };
}
