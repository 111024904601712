import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AccountService,
  AlertService,
  CampaignService,
  CampaignTrackingService,
  ReportsConfigService,
  UserService,
  CampaignProductService,
  CRMCampaignService,
  StorageService,
  FunnelStepService,
  RefundReportService,
  MarketplaceReportService, PaymentUpdaterReportService, MerchantService, CRMService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {ReportsAbstractComponent} from "./reports-abstract.component";
import {config} from '../../config/config';
import {takeUntil} from 'rxjs/operators';
import {Campaign, Pager, TrackingSource} from '../_models';

@Component({
  selector: 'payment-updater-reports',
  templateUrl: './templates/payment-updater-report.component.html',
  styleUrls: ['./styles/reports.component.css'],
})
export class PaymentUpdaterReportComponent extends ReportsAbstractComponent{
  public fields = {
    dimensions: [
      [
        {model: "username", label: "Session ID", default: false, disabled: false},
        {model: "source", label: "Path channel", default: false, disabled: false},
        {model: "tracking_source", label: "Traffic source", default: false, disabled: false},
        {model: "sid1", label: "Source sub 1", default: false, disabled: false},
        {model: "sid2", label: "Source sub 2", default: false, disabled: false},
        {model: "sid3", label: "Source sub 3", default: false, disabled: false},
        {model: "customer_name", label: "Customer Name", default: false, disabled: false},
        {model: "email", label: "Customer Email", default: false, disabled: false},
      ],
      [
        {model: "phone", label: "Customer Phone", default: false, disabled: false},
        {model: "campaign_name", label: "Brand", default: false, disabled: false},
        {model: "error", label: "Error Message", default: false, disabled: false},
        {model: "note", label: "CRM Note", default: false, disabled: false},
        {model: "path_name", label: "Path Name", default: false, disabled: false},
        {model: "recording_url", label: "Video Recording", default: false, disabled: false},
        {model: "aff_id", label: "Affiliate", default: false, disabled: false},
        {model: "sub1", label: "Aff sub 1", default: false, disabled: false},
      ],
      [
        {model: "sub2", label: "Aff sub 2", default: false, disabled: false},
        {model: "sub3", label: "Aff sub 3", default: false, disabled: false},
        {model: "country", label: "Country", default: false, disabled: false},
        {model: "device_type", label: "Device type", default: false, disabled: false},
        {model: "browser_name", label: "Browser", default: false, disabled: false},
        {model: "os_version", label: "Device OS version", default: false, disabled: false},
        {model: "mobile_vendor", label: "Device brand", default: false, disabled: false},
        {model: "mobile_model", label: "Device model", default: false, disabled: false},
      ],
      [
        {model: "ip_address", label: "IP Address", default: false, disabled: false},
        {model: "action_result", label: "Session Action Result", default: false, disabled: false},
        {model: "result", label: "Session result", default: false, disabled: false},
        {model: "session_action_status", label: "Session Action Status", default: false, disabled: false},
      ],
    ],
    timeInterval: [],
    statistics: [
      [
        {model: "session_count", label: "Session Quantity", default: false, disabled: false},
        {model: "revenue", label: "Revenue", default: false, disabled: false},
      ],
      [
        {model: "customer_count", label: "Customer Quantity", default: false, disabled: false},
        {model: "dropoffs", label: "Dropoffs", default: false, disabled: false},
      ],
      [
        {model: "errors_count", label: "Total Failures", default: false, disabled: false},
        {model: "failed_tries", label: "Failed Tries", default: false, disabled: false},
      ],
      [
        {model: "converted", label: "Total Converted", default: false, disabled: false},
      ]
    ],
    calculations: [
      [
        {model: "conversion_rate", label: "Conversion Rate", default: false, disabled: false},
      ], [], [], []
    ],
    exclude: [
      [
        {model: "exclude_tests", label: "Tests", default: true, disabled: false, isGlobal: false},
      ],
      [
        {model: "exclude_zeroes", label: "All Zeroes", default: true, disabled: false, isGlobal: false},
      ], [], [],
    ],
    filters: [
      [
        {model: "campaign_id", label: "Brand", default: false, disabled: false},
      ],
      [
        {model: "tracking_source", label: "Traffic source", default: "", disabled: false},
      ],
      [
        {model: "source", label: "Source", default: "", disabled: false},
      ], []
    ],
    filtersData: [
      [
        {model: "campaign_id", filter: "campaign_id", type: "multi-select", label: "Brand", default: "", options: []},
        {model: "source", filter: "source", type: "multi-select", label: "Channel source", default: "",
          options: [
            {id: "Web", text: "Web"},
            {id: "Email", text: "Email"},
            {id: "Phone", text: "Phone"},
            {id: "Social Media", text: "Social Media"},
          ]},
        {model: "tracking_source", filter: "tracking_source", type: "multi-select", label: "Traffic source", default: "",
          options: []},
      ]
    ]
  };
  public showDateFilterTypeField = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    public reportsService: PaymentUpdaterReportService,
    protected modalService: NgxSmartModalService,
    protected campaignService: CampaignService,
    protected reportsConfigService: ReportsConfigService,
    protected trackingSourceService: CampaignTrackingService,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignProductService: CampaignProductService,
    protected crmCampaignService: CRMCampaignService,
    protected storageService: StorageService,
    protected stepService: FunnelStepService,
    protected refundReportService: RefundReportService,
    protected merchantService: MerchantService,
    protected crmService: CRMService
  ) {
    super(router, location, route, alertService, formBuilder, reportsService, modalService, campaignService,
      reportsConfigService, trackingSourceService, userService, accountService, campaignProductService,
      crmCampaignService, storageService, stepService, refundReportService, merchantService, crmService)
  }

  ngOnInit() {
    super.ngOnInit();
  }

  fillAccountDependantFilters(items?) {
    let accounts = [];
    if (items === undefined) {
      items = this.form.value.account;
    }
    items.forEach(item => {
      accounts.push(item.id);
    })
    let queryParams = {account_id: accounts, page: 1, page_size: config.maxPageSize};
    let campaignFormData = [];
    this.initiateAccountFiltersOperations();
    this.campaignService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((item: Campaign) => {
            campaignFormData.push({id: item.id, text: item.display_name});
          })
          if (this.loadedConfiguration) {
            this.loadConfiguration(this.loadedConfiguration);
          }
        },
        error => {
          this.handleError(error);
        }
      );

    let trackingSourcesFromData = [];
    this.trackingSourceService.list(queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: TrackingSource[]) => {
          data.forEach((item: TrackingSource) => {
            trackingSourcesFromData.push({id: item.source, text: item.source});
          })
        },
        error => {
          this.handleError(error);
        }
      );

    this.filtersDynamicOptions['campaign_id'] = campaignFormData;
    this.filtersDynamicOptions['campaign'] = campaignFormData;
    this.filtersDynamicOptions['tracking_source'] = trackingSourcesFromData;
  }
}
