import { SafeResourceUrl } from '@angular/platform-browser';
import { BaseModel, Image } from '.';
import { CustomStepCategoryEnum, OfferIntents } from './funnel';
import { ActionType } from './funnel-action';
import { defaultThemeColor, greyFontColor, transparent, whiteColor } from './style';

export interface Breadcrumb {
  label: string;
  url?: any[];
}

export enum TemplateCategory {
  Downsell,
  Upsell,
  Survey,
  Support,
}

export enum OfferTypes {
  Support,
  Downsell,
  Upsell,
  SubPath,
  CustomerPortal,
  Survey,
}

export const OfferTypeLabels = {
  [OfferTypes.Downsell]: 'downsell',
  [OfferTypes.Upsell]: 'upsell',
  [OfferTypes.Survey]: 'survey',
  [OfferTypes.Support]: 'support',
  [OfferTypes.CustomerPortal]: 'customer-portal',
};

export enum StepElements {
  Banner = 'banner',
  Body = 'body',
  Media = 'media',
  Buttons = 'buttons',
  Response = 'response',
  Discount = 'discount',
  DiscountSettings = 'discountSettings',
  Exchangeables = 'exchangeables',
  Actions = 'actions',
  SurveyOptions = 'surveyOptions',
  TimeDelay = 'timeDelay',
  TimeOptions = 'timeOptions',
}

export const OfferIntentChildTypes = {};
OfferIntentChildTypes[OfferIntents.CancelOrder] = [
  OfferIntents.CancelOrder,
  OfferIntents.CancelSubscriptionHold,
  OfferIntents.CancelTrialHold,
];
OfferIntentChildTypes[OfferIntents.ReturnOrder] = [
  OfferIntents.ReturnOrder,
  OfferIntents.ReturnSubscription,
  OfferIntents.ReturnTrial,
];

export const OfferIntentParents = {};
Object.keys(OfferIntentChildTypes).forEach((parent) => {
  OfferIntentChildTypes[parent].forEach((child) => {
    OfferIntentParents[child] = parent;
  });
});

export const OfferIntentChildTypeLabels = {
  [OfferIntents.CancelOrder]: 'One-Time',
  [OfferIntents.CancelSubscriptionHold]: 'Subscription',
  [OfferIntents.CancelTrialHold]: 'Trial',
  [OfferIntents.ReturnOrder]: 'Straight Sale',
  [OfferIntents.ReturnSubscription]: 'Recurring',
  [OfferIntents.ReturnTrial]: 'Trial',
};

export const OfferIntentLabels = {
  [OfferIntents.CancelOrder]: 'Cancel Order',
  [OfferIntents.CancelSubscriptionHold]: 'Cancel Subscription Order',
  [OfferIntents.CancelTrialHold]: 'Cancel Trial Order',
  [OfferIntents.ReturnOrder]: 'Return Order',
  [OfferIntents.ReturnSubscription]: 'Return Subscription Order',
  [OfferIntents.ReturnTrial]: 'Return Trial Order',
  [OfferIntents.CancelSubscription]: 'Cancel Subscription',
  [OfferIntents.CancelTrial]: 'Cancel Trial',
};

export const StepCategoryDefaultActions: {
  [key in CustomStepCategoryEnum]?: [ActionType] | [ActionType, ActionType];
} = {
  [CustomStepCategoryEnum.CancelOrderSurvey]: [ActionType.Cancel],
  [CustomStepCategoryEnum.CancelSubSurvey]: [ActionType.Cancel],
  [CustomStepCategoryEnum.CancelTrialSurvey]: [ActionType.Cancel],
  [CustomStepCategoryEnum.ReturnSurvey]: [ActionType.RMA],
  [CustomStepCategoryEnum.FixedAmountDownsell]: [ActionType.Discount, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.PercentageDownsell]: [ActionType.Discount, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.PauseSub]: [ActionType.BillingCycle, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.ExtendTrial]: [ActionType.BillingCycle, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.OfferDetails]: [ActionType.CancelDelayed, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.LossAversion]: [ActionType.CancelDelayed, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.Testimonial]: [ActionType.CancelDelayed, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.RefundWithDiscount]: [ActionType.Refund, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.DiscountFutureOrder]: [ActionType.Discount, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.ReturnOrderRMA]: [ActionType.Refund, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.SwapProduct]: [ActionType.ChangeProduct, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.UpsellOrder]: [ActionType.AddProduct, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount]: [ActionType.Reactivate, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.AdjustShippingFrequency]: [ActionType.BillingCycle, ActionType.CancelDelayed],
  [CustomStepCategoryEnum.PauseSurvey]: [ActionType.BillingCycle, ActionType.CancelDelayed],
};

export enum ActionHoldoffType {
  NoDelay = 0,
  BrandSetting = null,
  Custom = 1,
}

export enum OrderType {
  OneTime = 1,
  Subscription = 2,
  Trial = 3,
}

export const OrderTypeLabels = {
  [OrderType.OneTime]: 'One-Time',
  [OrderType.Subscription]: 'Subscription',
  [OrderType.Trial]: 'Trial',
};

export enum StepTableFilters {
  None,
  Name,
  Type,
  LastModified,
  DateCreated,
}

export enum StepStatuses {
  Enabled,
  Disabled,
  Draft,
  All,
}

export const StepStatusLabels = {
  [StepStatuses.Enabled]: 'Active',
  [StepStatuses.Disabled]: 'Inactive',
  [StepStatuses.Draft]: 'Draft',
  [StepStatuses.All]: 'All',
};

export enum StepTableStatuses {
  Active,
  Inactive,
  Draft,
}

export const StepTableStatusLabels = {
  [StepTableStatuses.Active]: 'Active',
  [StepTableStatuses.Inactive]: 'Inactive',
  [StepTableStatuses.Draft]: 'Draft',
};

export enum StepDateTypes {
  LastModified,
  DateCreated,
}

export const StepTableDateLabels = {
  [StepDateTypes.LastModified]: 'Last Modified',
  [StepDateTypes.DateCreated]: 'Date Created',
};

export enum StepPopupActions {
  Create,
  Update,
  Duplicate,
  Delete,
  CreateTemplate,
}

export enum StepMediaCategory {
  NoMedia = 0,
  Product = 1,
  Fixed = 2,
  Embed = 3,
}

export enum StepMediaSubCategory {
  Product = 1,
  Tagged = 2,
}

export enum StepFixedMediaCategory {
  Gallery = 0,
  Upload = 1,
  Widget = 2,
}

export enum StepEmulatorDevices {
  Mobile,
  Tablet,
  Desktop,
}

export enum StepEmulatorDeviceWidths {
  Mobile = 430,
  Tablet = 840,
  Desktop = 1000,
}

export enum StepEmulationTypes {
  Preview,
  Response,
}

export const embedURLs = {
  youtube: 'https://www.youtube.com/embed',
  vimeo: 'https://player.vimeo.com/video',
  dailyMotion: 'https://www.dailymotion.com/embed/video',
};

export enum EmbedPlatforms {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  dailyMotion = 'dailymotion',
}

export interface StepTemplate {
  title: string;
  description: string;
  type?: number;
  icon: string;
  offer_type: OfferTypes;
  offer_intents?: OfferIntents[];
  disabled?: boolean;
}

export enum StepButtonTypes {
  Confirm,
  Back,
}

export interface StepButtons {
  confirmButton: StepButtonDetails;
  backButton: StepButtonDetails;
}

export interface StepButtonDetails {
  content?: string;
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  borderRadius?: string;
  brandBackground: string;
  brandBorder: string;
  brandFont: string;
  style?: StepButtonShapes;
  isBackgroundDisabled?: boolean;
  isBorderDisabled?: boolean;
  isFontDisabled?: boolean;
  type: StepButtonControlTypes;
  shapesPopup: boolean;
  controlPopup: boolean;
}

export enum StepButtonControlTypes {
  Theme,
  Custom,
}

export enum StepButtonShapes {
  Flat,
  Modern,
  Pill,
  FlatOutline,
  ModernOutline,
  PillOutline,
  TextOnly,
}

export const StepButtonShapeStyles = {
  [StepButtonShapes.Flat]: {
    backgroundColor: defaultThemeColor,
    borderColor: whiteColor,
    borderRadius: '0px',
    isBackgroundDisabled: false,
    isBorderDisabled: false,
    isFontDisabled: false,
  },
  [StepButtonShapes.Modern]: {
    backgroundColor: defaultThemeColor,
    borderColor: whiteColor,
    borderRadius: '5px',
    isBackgroundDisabled: false,
    isBorderDisabled: false,
    isFontDisabled: false,
  },
  [StepButtonShapes.Pill]: {
    backgroundColor: defaultThemeColor,
    borderColor: whiteColor,
    borderRadius: '40px',
    isBackgroundDisabled: false,
    isBorderDisabled: false,
    isFontDisabled: false,
  },
  [StepButtonShapes.FlatOutline]: {
    backgroundColor: whiteColor,
    borderColor: defaultThemeColor,
    borderRadius: '0px',
    isBackgroundDisabled: true,
    isBorderDisabled: false,
  },
  [StepButtonShapes.ModernOutline]: {
    backgroundColor: whiteColor,
    borderColor: defaultThemeColor,
    borderRadius: '5px',
    isBorderDisabled: false,
    isBackgroundDisabled: true,
  },
  [StepButtonShapes.PillOutline]: {
    backgroundColor: whiteColor,
    borderColor: defaultThemeColor,
    borderRadius: '40px',
    isBackgroundDisabled: true,
    isBorderDisabled: false,
  },
  [StepButtonShapes.TextOnly]: {
    fontColor: greyFontColor,
    backgroundColor: transparent,
    borderColor: transparent,
    borderRadius: '0px',
    isBackgroundDisabled: true,
    isBorderDisabled: true,
    isFontDisabled: false,
  },
};

export interface StepSurveyResponseCategory extends BaseModel {
  category: string;
  reason: string;
  categoryId: string | number;
}

export interface StepResponseScreen {
  detailButton: Omit<
    StepButtonDetails,
    'type' | 'shapesPopup' | 'controlPopup' | 'brandBackground' | 'brandBorder' | 'brandFont'
  >;
  continueButton: Omit<
    StepButtonDetails,
    'type' | 'shapesPopup' | 'controlPopup' | 'brandBackground' | 'brandBorder' | 'brandFont'
  >;
  titleContent: string;
  responseContent: string;
}

export interface StepTextual {
  banner?: StepTextualBanner;
  body: StepTextualBody;
}

export interface StepTextualBanner {
  value: string;
  background: string;
}

export interface StepTextualBody {
  label: string;
  content: string;
}

export enum StepMediaPanels {
  Step = 1,
  Response = 2,
}

export interface StepMedia {
  category: StepMediaCategory;
  subCategory: StepMediaSubCategory;
  fixedImage?: {
    [StepEmulatorDevices.Mobile]?: Image;
    [StepEmulatorDevices.Tablet]?: Image;
    [StepEmulatorDevices.Desktop]?: Image;
    productImage?: Image;
    category: StepFixedMediaCategory;
  };
  embedVideo?: {
    url: string;
    isURLValid: boolean;
    sanitizedURL?: SafeResourceUrl | null;
  };
  placeholderImage?: string;
}

export interface StepResponseMedia {
  category: StepMediaCategory;
  fixedImage?: {
    [StepEmulatorDevices.Mobile]?: Image;
    [StepEmulatorDevices.Tablet]?: Image;
    [StepEmulatorDevices.Desktop]?: Image;
    productImage?: Image;
    category: StepFixedMediaCategory;
  };
  embedVideo?: {
    url: string;
    isURLValid: boolean;
    sanitizedURL?: SafeResourceUrl | null;
  };
}

export enum StepImageSizes {
  Rectangle,
  Square,
}

export interface StepProductsPreview {
  data: any;
  loading: boolean;
}

/* Editor Ids */
export enum StepEditorIds {
  Banner = 'bannerEditor',
  Label = 'labelEditor',
  Content = 'contentEditor',
  ConfirmButton = 'confirmButtonEditor',
  BackButton = 'backButtonEditor',
  Response = 'responseEditor',
  ResponseTitle = 'responseTitleEditor',
}

/* Layout Models */
export enum StepElementTypes {
  Textual,
  Media,
  Buttons,
  ResponseScreen,
  SurveyOptions,
  Discount,
  DiscountSettings,
  TimeDelay,
  TimeOptions,
  Exchangeables,
  Actions,
}

export interface StepLayout {
  type: StepElementTypes;
  layout:
    | StepTextualLayout
    | StepImagesLayout
    | StepResponseScreenLayout
    | StepTimeOptionsLayout
    | StepDiscountSettingsLayout
    | StepDiscountLayout
    | StepButtonsLayout;
}

export interface StepBasicLayout {
  title: string;
  subtitle?: string;
}

export interface StepTextualLayout {
  banner?: StepBasicLayout;
  label: StepBasicLayout;
  content: StepBasicLayout;
}

export interface StepImagesLayout extends StepBasicLayout {
  placeholderImage: string;
  enabled: boolean;
}

export interface StepResponseScreenLayout extends StepBasicLayout {
  responseTitle: StepBasicLayout;
  response: StepBasicLayout;
}

export interface StepTimeOptionsLayout extends StepBasicLayout {
  type: StepTimeOptionTypes;
  items: {
    value: number;
    unit: StepDurationUnits;
  }[];
}

export enum StepTimeOptionTypes {
  ShippingFreq,
  PauseOptions,
}

export interface StepDiscountSettingsLayout extends StepBasicLayout {
  type: StepDiscountSettingTypes;
}

export enum StepDiscountSettingTypes {
  DiscountRefund,
  FutureOrder,
}

export interface StepDiscountLayout extends StepBasicLayout {
  inputTitle?: string;
  type?: StepDiscountTypes;
}

export enum StepDiscountTypes {
  Amount,
  Percentage,
}

export interface StepSurveyOptionsLayout extends StepBasicLayout {
  items: StepSurveyOption[];
}

export interface StepSurveyOption {
  reason: string;
  category: string;
}

export interface StepButtonsLayout extends StepBasicLayout {
  confirmTitle: string;
  backTitle: string;
}

export enum StepDurationUnits {
  Hours,
  Days,
  Weeks,
  Months,
}

export interface StepTemplateFolder extends BaseModel {
  name: string;
  category: number;
  steps_count: number;
  thumbnails: string[];
  is_global: boolean;
  showThreeDotMenu?: boolean;
}
