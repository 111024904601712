import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {PhoneEntryFunnelConfig, CampaignMenu, Campaign, CampaignProduct, getNextMenuSectionId} from "../_models";
import {BehaviorSubject, Observable} from 'rxjs';
import {ClipboardService} from "./clipboard.service";
import {map} from "rxjs/operators";

@Injectable()
export class CampaignService extends PaginationService {
  private subject = new BehaviorSubject<CampaignMenu>(null);

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'campaigns');
  }

  verify(campaign_id: string, token: string) {
    return this.http.post('campaigns/verify/', {'campaign_id': campaign_id, 'token': token});
  }

  summary(campaign_id: string) {
    return this.http.get('campaigns/' + campaign_id.toString() + '/summary/');
  }

  savePhoneEntryFunnel(id: string | number, data: PhoneEntryFunnelConfig) {
    return this.http.post('campaigns/' + id.toString() + '/phone_entry/', data);
  }

  showMenu(campaign: Campaign | null, section: string, isNew: boolean, isHybrid: boolean) {
    this.subject.next({ campaign: isNew ? null : campaign, activeSection: section, isHybrid: isHybrid });
  }

  hideMenu() {
    this.subject.next(null);
  }

  getMenu(): Observable<CampaignMenu> {
    return this.subject.asObservable();
  }

  getNextMenuRoute() {
    return this.getMenu().pipe(map((menu: CampaignMenu) => {
      if (!menu) {
        return null
      }
      const menuType = menu.isHybrid ? 'hybrid' : 'normal';

      return getNextMenuSectionId(menuType, menu.activeSection);
    }));
  }

  checkSubdomain(subdomain: string) {
    return this.http.post('subdomain/check/', {subdomain: subdomain});
  }

  copyProducts(products: CampaignProduct[]) {
    this.clipboardService.copy('campaign_products', products);
  }

  canPasteProducts() : boolean {
    return this.clipboardService.canPaste('campaign_products');
  }

  pasteProducts() {
    return this.clipboardService.paste('campaign_products');
  }
}
