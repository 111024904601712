import { BaseModel } from './base-model';
import { FunnelFulfillmentType } from './funnel';
import { CardType } from './order';
import { SessionSource } from './session-enums';

export interface Audience extends BaseModel {
  name: string;
  updated: string;
  paths: AudiencePaths[];
  fulfillment_type: FunnelFulfillmentType;
  min_billing_cycle: number;
  max_billing_cycle: number;
  is_3ds: boolean;
  merchant_ids: string[];
  cc_types: CardType[];
  states: string[];
  crm_campaigns: string[] | number[];
  use_crm_campaign_filter: boolean;
  campaign_products: string[] | number[];
  use_product_filter: boolean;
  traffic_sources: SessionSource[];
  is_modified: boolean;
  aff_ids: string[];
}

export interface AudiencePaths {
  id: number;
  name: string;
}

export enum AudienceStatus {
  All = 0,
  Active = 1,
  Draft = 2,
  Inactive = 3,
  Assigned = 4,
  Unassigned = 5,
}

export const AudienceStatusLabels = {};
AudienceStatusLabels[AudienceStatus.All] = 'All';
AudienceStatusLabels[AudienceStatus.Active] = 'Active';
AudienceStatusLabels[AudienceStatus.Draft] = 'Draft';
AudienceStatusLabels[AudienceStatus.Inactive] = 'Inactive';
AudienceStatusLabels[AudienceStatus.Assigned] = 'Assigned';
AudienceStatusLabels[AudienceStatus.Unassigned] = 'Unassigned';

export enum AudienceDateTypes {
  LastModified = 'lastModified',
  DateCreated = 'dateCreated',
}

export enum AudiencePopup {
  Create = 0,
  Update = 1,
  Duplicate = 2,
  Delete = 3,
}

export enum TrafficSource {
  DIRECTLINK = 0,
  EMAIL = 1,
  PHONE = 2,
  SMS = 3,
  API = 4,
}

export enum AudienceConditionType {
  FulfillmentType = 'Fulfillment Type',
  BillingCycle = 'Billing Cycle',
  TransactionFilters = 'Transaction Filters',
  Location = 'Location',
  CampaignFilters = 'Campaign Filters',
  ProductFilters = 'Product Filters',
  TrafficSource = 'Traffic Source',
  UseProductFilters = 'ProductFilters',
  UseCampaignFilters = 'CampaignFilters',
}

export enum AudienceBillingCycle {
  One = 1,
  Two = 2,
  Three = 3,
  Custom = 4,
}
