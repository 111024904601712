import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  StepElementTypes,
  StepLayout,
  CustomStepCategoryEnum,
  Funnel,
  CampaignProduct,
  User,
} from '../../../_models';
import {
  AlertService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StepTemplateService,
} from '../../../../app/_services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StepsInvalidityFields } from '../../step-data';

@Component({
  selector: 'app-step-form',
  templateUrl: './step-form.component.html',
  styleUrls: ['./step-form.component.scss'],
})
export class StepFormComponent implements OnInit {
  @Input() layout: StepLayout[] = [];
  @Input() stepType: CustomStepCategoryEnum;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() theme: string;
  @Input() isNew: boolean;
  @Input() stepId: string = '';
  @Input() funnelId: string = '';
  @Input() funnel: Funnel;
  @Input() editTemplate: boolean;
  @Input() templateId: string = '';
  @Input() product: CampaignProduct = {} as CampaignProduct;
  @Input() isPurchaseType: boolean;
  @Input() useExternalButtons: boolean;
  @Input() isSurveyStep = false;
  @Input() user: User;
  isLoading = false;
  stepElements = StepElementTypes;
  @ViewChildren(
    'stepTextualElement, stepDiscount, stepMediaElement, stepSurveyOptions, stepButton, stepTimeDelayElement, stepDiscountSettings, stepResponseScreenElement'
  )
  childComponents: QueryList<ElementRef>;

  constructor(
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected loaderService: LoaderService,
    protected funnelStepService: FunnelStepService,
    protected stepEmulatorService: StepEmulatorService,
    protected stepService: StepTemplateService
  ) {}

  ngOnInit() {
    this.loaderService.loader$.subscribe((state) => {
      this.isLoading = state;
    });
  }

  scrollToFirstErrorComponent(): ElementRef | null {
    let firstErrorElement: ElementRef | null = null;

    this.childComponents.forEach((component: any) => {
      if (firstErrorElement) {
        return;
      }

      const hasError = StepsInvalidityFields.some((field) => component[field]);

      if (hasError) {
        firstErrorElement = component.getElementRef();
      }
    });

    return firstErrorElement;
  }
}
