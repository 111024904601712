import {Component, OnInit} from '@angular/core';
import {AlertService, EmailIntegrationService} from '../_services';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailIntegration, EmailIntegrationType} from '../_models';
import {GmailService} from '../_services/gmail.service';
import {error} from 'protractor';

@Component({
    moduleId: module.id.toString(),
    selector: 'app-integration-select',
    templateUrl: './email-integration-select.component.html',
    styleUrls: ['./email-integration-select.component.scss']
})
export class EmailIntegrationSelectComponent implements OnInit {
  public campaignId: string | number;
  public loading: boolean = false;
  constructor(
    protected emailIntegrationService: EmailIntegrationService,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected gmailService: GmailService,
    protected router: Router
  ) {
  }

  ngOnInit() {
    this.campaignId = this.route.snapshot.params['campaign'];
    this.emailIntegrationService.setCampaignId(this.campaignId);
    this.gmailService.setCampaignId(this.campaignId);
  }

  performGoogleAuth() {
    this.loading = true
    this.gmailService.performAuthentication();
  }
}
