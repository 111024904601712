import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

export interface FilterValue {
  key: any;
  value: string;
}

export interface Filter {
  name: string;
  values: FilterValue[];
  selectedValues: any[];
  showSubPopup: boolean;
}

@Component({
  selector: 'table-filter-bar',
  templateUrl: './table-filter-bar.component.html',
  styleUrls: ['./table-filter-bar.component.scss'],
})
export class TableFilterBarComponent implements OnInit {
  @Input() filterLabel = '';
  @Input() filters: Filter[] = [];
  @Input() showPills: boolean;
  @Input() maxPills: number;
  @Output() filterChanged = new EventEmitter<{ filterName: string; selectedValues: string[] }>();
  showFilterPopup = false;
  showRemainingFilters = false;
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit() {}

  toggleMainFilterPopup(): void {
    if (this.showFilterPopup) {
      this.closeAllPopups();
    } else {
      this.showFilterPopup = true;
    }
  }

  toggleSubPopup(filter: Filter): void {
    console.log('hi');
    this.filters.forEach((f) => {
      if (f !== filter) {
        f.showSubPopup = false;
      }
    });
    filter.showSubPopup = !filter.showSubPopup;
  }

  isValueSelected(filterName: string, valueKey: string): boolean {
    const filter = this.filters.find((f) => f.name === filterName);
    if (!filter || !filter.selectedValues) {
      return false;
    }
    return filter.selectedValues.includes(valueKey);
  }

  onCheckboxChange(filterName: string, valueKey: string): void {
    const filter = this.filters.find((f) => f.name === filterName);
    if (filter) {
      if (!filter.selectedValues) {
        filter.selectedValues = [];
      }

      const index = filter.selectedValues.indexOf(valueKey);
      if (index === -1) {
        filter.selectedValues.push(valueKey);
      } else {
        filter.selectedValues.splice(index, 1);
      }

      this.filterChanged.emit({
        filterName: filterName,
        selectedValues: filter.selectedValues,
      });
    }
  }

  getActiveFilters(): string {
    return this.filters
      .filter((filter) => {
        return filter.selectedValues && filter.selectedValues.length > 0;
      })
      .map((filter) => {
        const selectedLabels = filter.values
          .filter((value) => {
            return filter.selectedValues && filter.selectedValues.includes(value.key);
          })
          .map((value) => value.value);
        return selectedLabels.join(', ');
      })
      .filter((value) => value !== '')
      .join(', ');
  }

  getSelectedValues(filter: Filter): string[] {
    if (!filter.selectedValues || filter.selectedValues.length === 0) {
      return [];
    }
    return filter.values.filter((v) => filter.selectedValues.includes(v.key)).map((v) => v.value);
  }

  removeFilter(filterName: string, value: string) {
    const filter = this.filters.find((f) => f.name === filterName);
    if (!filter) return;

    const valueObj = filter.values.find((v) => v.value === value);
    if (!valueObj) return;

    const index = filter.selectedValues.indexOf(valueObj.key);
    if (index > -1) {
      filter.selectedValues.splice(index, 1);
      this.filterChanged.emit({
        filterName: filterName,
        selectedValues: filter.selectedValues,
      });
    }
  }

  hasSelectedFilters(): boolean {
    if (!this.filters) {
      return false;
    }
    return this.filters.some((filter) => filter.selectedValues && filter.selectedValues.length > 0);
  }

  getTotalShownPills(filterIndex: number, valueIndex: number): number {
    let count = 0;
    for (let i = 0; i <= filterIndex; i++) {
      const values = this.getSelectedValues(this.filters[i]);
      if (i === filterIndex) {
        count += valueIndex + 1;
      } else {
        count += values.length;
      }
    }
    return count;
  }

  getRemainingCount(): number {
    const total = this.filters.reduce((sum, filter) => sum + this.getSelectedValues(filter).length, 0);
    return Math.max(0, total - this.maxPills);
  }

  closeRemainingFilters(): void {
    this.showRemainingFilters = false;
  }

  closeAllPopups(): void {
    this.filters.forEach((filter) => (filter.showSubPopup = false));
    this.showFilterPopup = false;
  }
}
