import {Address, BaseModel, User} from './index';

export enum PhoneNumberTypeEnum {
  Local = 0,
  TollFree = 1,
  National = 2,
}

export const PhoneNumberTypeLabels = [
  'Local',     // 0
  'Toll-Free', // 1
  'National',  // 2
];

export interface PhoneNumberType {
  value: number;
  label: string;
}

export enum PhoneUseTypeEnum {
  VoiceOnly = 1,
  SMSOnly = 2,
  VoiceAndSMS = 3
}

export enum PhoneResourceType {
  Twilio = 'TwilioPhone',
  Telnyx = 'TelnyxPhone',
}

export enum PhoneProviderType {
  Twilio = 1,
  Telnyx = 2,
}

export const PhoneProviderTypeLabels = {
  TwilioPhone: 'Twilio',
  TelnyxPhone: 'Telnyx',
};

export enum PhoneStatus {
  Active = 1,
  Pending = 2,
  Deleted = 3,
  Failed = 4,
  RequirementPending = 5,
  PortInPending = 6,
  PortOutPending = 7,
  PortedOut = 8,
}

export const PhoneStatusLabels = [
  '',           // 0
  'Active',     // 1
  'Pending',    // 2
  'Deleted',    // 3
  'Failed',     // 4
  'Requirement Pending', // 5
  'Port-In Pending', // 6
  'Port-Out Pending', // 7
  'Ported Out', // 8
];

export interface PhoneSearchParam {
  type: PhoneNumberTypeEnum;
  area_code: number;
  pattern: string;
  country: string;
  provider_type: PhoneProviderType;
}

export interface PhoneSearchResult {
  value: string;
  label: string;
}

export interface Phone extends BaseModel {
  phone: string;
  address: Address | string | number;
  type: PhoneNumberTypeEnum;
  campaign: string | number;
  domain: string | number;
  use_type: PhoneUseTypeEnum;
  is_approved: boolean;
  resourcetype: PhoneResourceType;
  showProvider?: boolean;
  status: PhoneStatus;
  created?: string;
}

export function getPhoneLabel(phone: Phone) {
  let label = phone.phone;
  let parts = [];

  if (phone.showProvider) {
    parts.push(PhoneProviderTypeLabels[phone.resourcetype]);
  }

  if (phone.use_type === PhoneUseTypeEnum.SMSOnly) {
    parts.push('SMS Only');
  }
  else if (phone.use_type === PhoneUseTypeEnum.VoiceOnly) {
    parts.push('Voice Only');
  }
  else {
    parts.push('Voice & SMS')
  }

  if (phone.is_approved) {
    parts.push('Approved');
  }

  if (parts.length) {
    label += ' (' + parts.join(', ') + ')';
  }

  return label;
}

export enum PhoneCarrierType {
  Unknown = 0,
  Mobile = 1,
  Landline = 2,
  VIOP = 3
}

export const PhoneCarrierTypeLabels = [
  'Unknown',          // 0
  'Mobile',           // 1
  'Landline',         // 2
  'VOIP'              // 3
];
