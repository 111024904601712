import {PaginationService} from './pagination.service';
import {HttpClient} from '@angular/common/http';

export class EmailIntegrationBaseService extends PaginationService {
  public campaignId: string | number

  constructor(protected http: HttpClient, protected route: string) {
    super(http, route);
  }

  setCampaignId(id: string | number) {
    this.route = 'campaign/' + id.toString() + '/email_integrations';
    this.campaignId = id
  }
}
