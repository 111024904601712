import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailIntegrationType} from '../_models';
import {environment} from '../../environments/environment';
import {stringify} from 'querystring'
import {EmailIntegrationBaseService} from './email-integration-base.service';
import {Observable} from 'rxjs';

@Injectable()
export class GmailService extends EmailIntegrationBaseService {
  private clientId = environment.googleClientId
  private scope = 'https://www.googleapis.com/auth/gmail.modify'
  private baseAuthUrl = 'https://accounts.google.com/o/oauth2/auth'

  constructor(protected http: HttpClient) {
    super(http, 'campaign');
  }

  performAuthentication() {
    const params = {
      response_type: 'code',
      client_id: this.clientId,
      redirect_uri: window.location.origin + '/email-integration/gmail/auth/callback',
      scope: this.scope,
      state: stringify({
        campaign: this.campaignId,
        type: EmailIntegrationType.Gmail
      }),
      access_type: 'offline',
      include_granted_scopes: true
    }

    window.open(this.baseAuthUrl + '?' + stringify(params), '_blank');
  }

  create(obj: any): Observable<Object> {
    return this.http.post(this.route + '/', obj, {headers: {email_api: 'true'}});
  }

  delete(id) {
    return this.http.delete(this.route + '/' + id.toString() + '/', {headers: {email_api: 'true'}});
  }
}
