import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignService, PhoneService} from '../_services';
import {
  Campaign,
  CampaignSummary,
  CustomDomain, DeploymentLink,
  Pager,
  Phone,
  PhoneStatus,
  PhoneStatusLabels,
  SessionType,
  SessionTypeLabels
} from '../_models';
import {CrudGetComponent} from '../_directives';
import {CampaignDomainComponent} from '../campaign-domain';
import {forkJoin} from 'rxjs';
import {config} from '../../config/config';
import {DeploymentLinkService} from '../_services';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {copyToClipboard} from '../_helpers';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.css']
})
export class CampaignSummaryComponent extends CrudGetComponent implements OnInit {
  campaign: Campaign;
  @ViewChild("campaignDomains", { static: false }) campaignDomainsComponent: CampaignDomainComponent;
  summary: CampaignSummary;
  phones: Phone[];
  deploymentLinks: DeploymentLink[];
  domainsMap = {};
  copied: boolean = false;
  selectedLink: DeploymentLink = null;
  sections = [
    {
      label: 'Phone',
      isPhone: true,
      collapsed: false,
      columns: ['Phone Number', 'Domain', 'Status', 'Date Created'],
      canCopy: true,
      containerClass: 'phones__table'
    },
    {
      label: 'Domain',
      isDomain: true,
      collapsed: true,
      columns: ['Domain', 'Type', 'Status', 'Date Verified'],
      canCopy: false,
      containerClass: 'domains__table'
    },
    {
      collapsed: true,
      isDeploymentLink: true,
      label: 'Deployment Link',
      newButtonLabel: 'Link',
      canCopy: false,
      columns: ['Name', 'Status', 'Type', 'Target Page', 'Source Type', 'URL', 'Last Modified'],
      containerClass: 'links__table'
    },
  ];

  copyValue = copyToClipboard;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    protected campaignService: CampaignService,
    protected phoneService: PhoneService,
    private deploymentLinkService: DeploymentLinkService,
    private modalService: NgxSmartModalService
  ) {
    super(router, location, route, campaignService, alertService);
    this.title = 'Deployment'
  }

  get crms() {
    return this.summary && (this.summary.crms.length > 1 ? `${this.summary.crms.length} crms` : this.summary.crms.join(','))
  }

  get domains() {
    return (this.campaign && this.campaign.custom_domains) || [];
  }

  get marketplaceDomain() {
    return this.domains.find((domain) => domain.type === SessionType.Marketplace)
  }

  get paymentUpdaterDomain() {
    return this.domains.find((domain) => domain.type === SessionType.PaymentUpdate)
  }

  get customerPortalDomain() {
    return this.domains.find((domain) => domain.type === SessionType.CustomerPortal)
  }

  get helpDeskDomain() {
    return this.domains.find((domain) => domain.type === SessionType.HelpDesk)
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.subscribe(
      (campaign: Campaign) => {
        if (campaign) {
          this.campaign = campaign;
          (this.campaign.custom_domains || []).forEach((domain) =>{
            this.domainsMap[domain.id.toString()] = domain;
          })
          this.campaignService.showMenu(campaign, 'summary', false, campaign.is_hybrid);

          forkJoin([
            this.campaignService.summary(campaign.id.toString()),
            this.phoneService.list({'domain__in': campaign.custom_domains.map((domain: CustomDomain) => domain.id).join(','), page: 1, page_size: config.maxPageSize}),
            this.deploymentLinkService.list({'campaign': campaign.id.toString(), page: 1, page_size: config.maxPageSize}),
          ]).subscribe(
            (data: [CampaignSummary, Pager, Pager]) => {
              if (data) {
                this.summary = data[0];
                this.phones = data[1].results;
                this.deploymentLinks = data[2].results;
              }
              this.loading = false;
            },
            error => {
              this.handleError(error);
            }
          );
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getWidth(section) {
    if (section.isDeploymentLink) {
      return '10%';
    }
    return (100 / ((section.columns || []).length -1)).toFixed(2) + '%'
  }

  hasData(section) {
    if (section.isPhone) {
      return this.phones && !!this.phones.length
    } else if (section.isDomain) {
      return this.campaign.custom_domains && !!this.campaign.custom_domains.length
    } else if (section.isDeploymentLink) {
      return this.deploymentLinks && !!this.deploymentLinks.length
    }
  }

  getDomainType(domain: CustomDomain) {
    return SessionTypeLabels[domain.type];
  }

  getLinkType(deploymentLink: DeploymentLink) {
    return SessionTypeLabels[deploymentLink.link_type];
  }

  getPhoneStatus(phone: Phone) {
    return PhoneStatusLabels[phone.status]
  }

  getPhoneStatusClass(phone: Phone) {
    switch(phone.status){
      case PhoneStatus.Active:
      case PhoneStatus.PortedOut:
        return 'active';
      case PhoneStatus.Deleted:
      case PhoneStatus.Failed:
        return 'inactive';
      default:
        return 'pending'
    }
  }

  getLinkTargetPath(link: DeploymentLink) {
    return link.step_key && link.step_key.split('-').join(' ')
  }

  getLinkTrackingSource(link: DeploymentLink) {
    return link.tracking_source && link.tracking_source['source'] && link.tracking_source['source'].split('-').join(' ')
  }

  toggleSection(selectedIndex: number) {
    this.sections.forEach((section, index) => {
      if (index === selectedIndex) {
        section.collapsed = !section.collapsed;
      } else {
        section.collapsed = true;
      }
    })

  }

  openDocumentation(domain: CustomDomain, link: DeploymentLink) {
    this.campaignDomainsComponent.showDocumentationPopup(domain, link);
  }

  openQRCodeDialog(link: DeploymentLink) {
    this.selectedLink = link;
    this.modalService.getModal('QRCodeDialog').open();
  }

  handleClick(section) {
    if (section.isPhone) {
      this.router.navigateByUrl('/phone')
    } else if (section.isDomain && this.campaignDomainsComponent) {
      this.campaignDomainsComponent.create()
    } else if (section.isDeploymentLink) {
      this.campaignDomainsComponent.createLink()
    }
  }

  downloadQRCodeImage() {
    if (this.selectedLink) {
      let link = document.createElement("a");
      document.documentElement.append(link);
      const img = document.querySelector('.qrcode-container img');
      link.setAttribute("download", `${this.selectedLink.name}_qrcode.jpg`);
      link.href = img['src'];
      // Auto click the link
      link.click();
      link.remove();
    }
  }

  openQRCode() {
    const img = document.querySelector('.qrcode-container img');
    const base64Image = img['src'];
    // Convert Base64 to Blob
    const byteString = atob(base64Image.split(',')[1]); // Decode Base64
    const mimeType = base64Image.split(',')[0].match(/:(.*?);/)[1]; // Extract MIME type
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: mimeType });

    // Create an Object URL
    const objectURL = URL.createObjectURL(blob);

    // Open the image in a new tab
    window.open(objectURL, '_blank');
  }

  toggleDropdown(selectedDeploymentLink: DeploymentLink, event?: any) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.deploymentLinks.forEach((deploymentLink: DeploymentLink) => {
      if (selectedDeploymentLink && selectedDeploymentLink.id ===  deploymentLink.id) {
        deploymentLink['showDropdown'] = !deploymentLink['showDropdown'];
      } else {
        deploymentLink['showDropdown'] = false
      }
    })
  }

  editLink(event: Event, deploymentLink: DeploymentLink) {
    event.stopPropagation();
    event.preventDefault();
    this.selectedLink = deploymentLink;
    this.campaignDomainsComponent.generateLinks(this.selectedLink.domain as CustomDomain);
    this.toggleDropdown(null);
  }

  get brandConfiguredImageName() {
    if (this.campaign && this.campaign.active_domain && this.campaign.styles && this.campaign.styles.length > 0) {
      if ((this.campaign.is_hybrid && (!this.campaign.hybrid_entry_funnels || !this.campaign.hybrid_entry_funnels.length))) {
        return 'filled';
      } else if (this.campaign.visual_entry_funnels && this.campaign.visual_entry_funnels.length > 0) {
        return 'filled';
      }
    }
    return 'outline'
  }

  get supportConfiguredImageName() {
    if (this.campaign && this.campaign.support_phone && this.campaign.call_center_hours && this.campaign.call_center_hours.length > 0) {
      return 'filled'
    }
    return 'outline'
  }

  get productConfiguredImageName() {
    if (this.campaign && this.campaign.campaign_products && this.campaign.campaign_products.length > 0) {
      return 'filled'
    }
    return 'outline'
  }

  get helpDeskConfiguredImageName() {
    if (this.campaign && this.campaign.chatbot_enabled && this.campaign.faq_topic) {
      return 'filled'
    }
    return 'outline'
  }

  onLinkSaveComplete(data) {
    if (data) {
      const index = this.deploymentLinks.findIndex((link) => link.id === data.id);
      if (index > -1) {
        this.deploymentLinks[index] = {...data};
      } else {
        this.deploymentLinks.unshift(data);
      }
    }
    this.selectedLink = null;
  }
}
