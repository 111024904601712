import {
  BaseModel,
  Customer,
  Item,
  ItemStatus,
  FunnelInputTypeEnum,
  Campaign,
  Action,
  PhoneCarrierType,
  FunnelInputHistory,
  FunnelStepHistory,
  FunnelHistory,
  CRMTypeId,
  FunnelItemStatus, SessionFaq, FaqSearchHistory, FulfillmentStatus,
} from './index';
import {
  SessionType,
  PhoneCallResult,
  SessionActionStatus,
  SessionActionResult,
  RevenueType,
  SessionStatus,
  SessionSource,
  SessionResult,
  LifelineType,
  SessionMatchMethod,
  SessionInputStatus
} from './session-enums'

export function initializeSessionTypeFunnels(funnels: {}) {
  funnels[SessionType.Support] = [];
  funnels[SessionType.Marketplace] = [];
  funnels[SessionType.PaymentUpdate] = [];
}

export interface SessionBaseAction extends BaseModel {
  action_snapshot: Action;
  created: string;
}

export interface PhoneCallAction extends SessionBaseAction {
  call: string | number;
  call_input: string | number;
}

export interface SessionBaseInput extends BaseModel {
  input_snapshot: FunnelInputHistory;
  step_snapshot: FunnelStepHistory;
  created: string;
}

export interface PhoneCallInput extends SessionBaseInput {
  call: string | number;
  entered_value: string;
  phone_call_actions: PhoneCallAction[];
  executed_phone_call_actions: PhoneCallAction[];
}

export interface PhoneCall extends BaseModel {
  from_phone: string;
  to_phone: string;
  entered_phone: string;
  created: string;
  loop: number;
  result: PhoneCallResult;
  carrier: string;
  carrier_type: PhoneCarrierType;
  funnel_snapshot: FunnelHistory;
  step: string | number;
  phone_call_inputs: PhoneCallInput[];
  recording_id?: string;
  recording_url?: string;
}

export interface DeviceInfo extends BaseModel {
  browser_full_version: string;
  browser_major_version: number;
  browser_name: string;
  engine_name: string;
  engine_version: string;
  os_name: string;
  os_version: string;
  device_type: string;
  mobile_vendor: string;
  mobile_model: string;
}

export interface SessionAction extends SessionBaseAction {
  session: string | number;
  session_input: string | number;
  executed_session_input: string | number;
  status: SessionActionStatus;
  parent_session_action: string | number;
  result: SessionActionResult;
  revenue: number;
  revenue_type: RevenueType;
  refund_amount: number;
  billed_amount: number;
  discount_amount: number;
  recurring_amount: number;
  message: string;
  refund_flag: boolean;
  executed: string;
  execution_started: string;
}

export interface SessionBase extends BaseModel {
  username: string;
  status: SessionStatus;
  customers: Customer[];
  date_joined: string;
  faq_root_topic: string | number;
  step: string | number;
  label: string;
  source: SessionSource;
  required_fields: any[];
  help: string;
  can_undo: boolean;
  welcome_message: string;
  verify_message: string;
  match_message: string;
  status_messages: string[];
  item_statuses: ItemStatus[];
  classes: string[];
  can_go_home: boolean;
  is_first_step: boolean;
  thank_you_message: string;
  lifeline_timeout: number;
  lifeline_undo_count: number;
  lifeline_unmatched_count: number;
  lifeline: string | number;
  exit_survey: string | number;
  step_type: FunnelInputTypeEnum;
  banner: string;
  subtitle: string;
  content: string;
  is_call_center_open: boolean;
  is_popup: boolean;
  result: SessionResult;
  lifeline_type: LifelineType;
  campaign: Campaign;
  matched_by: SessionMatchMethod;
  device_info: DeviceInfo;
  is_billable: boolean;
  selected_item: string | number;
  new_item: string | number;
  tracking_source: string;
  sid1: string;
  sid2: string;
  sid3: string;
  funnel_snapshot: FunnelHistory;
  step_snapshot: FunnelStepHistory;
  ip_address: string;
  message: string;
  phone_call: PhoneCall;
  recording_url: string;
  entry_campaign: Campaign;
  latest_result: SessionResult;
  frequency: number;
  funnel_item_status: FunnelItemStatus;
  language: string;
  started: boolean;
  first_name: string;
  last_name: string;
  type: SessionType;
  domain: string;
  show_phone_link: boolean;
  last_update: string;
}

export interface SessionInput extends SessionBaseInput {
  session: string | number | SessionBase;
  autopick: boolean;
  status: SessionInputStatus;
  undo_step: string | number;
  input_data: any;
  session_actions: SessionAction[];
  executed_session_actions: SessionAction[];
  item: Item;
  new_item: Item;
  can_undo: boolean;
  cancel_reason: string;
  autopick_reason: string;
  lifeline_status: SessionResult;
  message: string;
  funnel_item_status?: FunnelItemStatus;
  item_status?: ItemStatus;
  fulfillment_status?: FulfillmentStatus;
}

export interface SessionEvent extends BaseModel {
  event_id: string;
  url: string;
  created: string;
}

export interface Session extends SessionBase {
  risk_score: number;
  friction_score: number;
  session_inputs: SessionInput[];
  sentry_events: SessionEvent[];
  child_sessions: SessionBase[];
  session_faqs?: SessionFaq[];
  faqs_search_phrases?: FaqSearchHistory[];
  revenue: number;
  session_time: number;
}

export interface ReportSessionInput {
  username: string,
  result: number,
  lifeline: string,
  date: string,
  week: number,
  month: number,
  year: number,
  hour: number,
  bypass: string,
  bypass_type: string,
  source: number,
  lifeline_type: number,
  status: number,
  aff_id: string,
  sid1: string,
  sid2: string,
  sid3: string,
  tracking_source: string,
  campaign_name: string,
  country: string,
  browser_name: string,
  os_version: string,
  device_type: string,
  mobile_vendor: string,
  mobile_model: string,
  carrier_type: string,
  category: string,
  product_name: string,
  cancelled: number,
  saved: number,
  sub1: string,
  sub2: string,
  sub3: string,
  revenue: number,
  revenue_type: string,
  downsell: number,
  upsell: number,
  save_amount: number,
  refund_quantity: number,
  refund_amount: number,
  return_amount: number,
  return_quantity: number,
  downsell_take_rate: number,
  revisions: number,
  exchangeable_take_rate: number,
  upsell_take_rate: number,
  save_rate: number,
  refund_rate: number,
  rma_rate: number,
  cancel_rate: number,
  crm_campaign_name: string,
  cancel_reason: string,
  fulfillment_status: number,
  partial_refund_rate: number,
  full_refund_rate: number,
  recording_url: string,
  exchangeable_product_name: string,
  crm_type: CRMTypeId,
  customer_id: string,
  order_id: string
  session_source: number;
}

export interface ReportsFormData {
  dimensions?: object,
  date_range?: object,
  timeInterval?: object,
  statistics?: object,
  calculations?: object,
  exclude?: object,
  filtersData?: object,
  filters?: object,
  timezone?: string,
  dateFilterType?: string,
  account?: Array<{ id: string | number, text: string }>
  sessionType?: string,
  spTouched?: string,
}
