import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, CampaignDomainService} from '../_services';
import {Campaign, CustomDomain, DeploymentLink, LifelinePhoneSetting, SessionType, SessionTypeLabels} from '../_models';
import {Form} from '../_forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeUntil} from "rxjs/operators";
import * as moment from "moment";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-domains',
  templateUrl: './campaign-domain.component.html'
})
export class CampaignDomainComponent extends Form implements OnInit {
  selectedDomain: CustomDomain;
  selectedLink: DeploymentLink;
  @Input('campaign') campaign: Campaign;
  @Input('isSummary') isSummary: boolean = false;
  @Input('deploymentLink') deploymentLink: DeploymentLink;
  @Output('linkSave') onLinkSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected alertService: AlertService,
    private domainService: CampaignDomainService,
    private modalService: NgxSmartModalService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  generateLinks(domain: CustomDomain) {
    this.selectedDomain = domain;
    this.modalService.getModal('linksDialog').open();
  }

  showDocumentationPopup(domain: CustomDomain, link?: DeploymentLink) {
    this.selectedDomain = domain;
    this.selectedLink = link;
    this.modalService.getModal('iframeDocumentationDialog').open();
  }

  create() {
    this.selectedDomain = {
      id: null,
      campaign: this.campaign.id,
      domain: null,
      is_verified: false,
      verified: null,
      cname_target: null,
      type: SessionType.Support,
      name: null,
      visual_entry_funnels: [],
      show_phone_link: LifelinePhoneSetting.Never,
    };
    this.openEditDialog();
  }

  createLink() {
    this.selectedLink = {
      id: null,
      sid1: null,
      sid2: null,
      sid3: null,
      tracking_source: null,
      name: null,
      step_key: null,
      domain: this.selectedDomain && this.selectedDomain.id,
      campaign: this.campaign && this.campaign.id,
    } as DeploymentLink;
    this.generateLinks(this.selectedDomain);
  }

  edit(domain: CustomDomain) {
    this.selectedDomain = domain;
    this.openEditDialog();
  }

  editPhone(domain: CustomDomain) {
    this.selectedDomain = domain;
    this.openPhoneDialog();
  }

  editVisualFunnels(domain: CustomDomain) {
    this.selectedDomain = domain;
    this.openFunnelsDialog();
  }

  delete(domain: CustomDomain) {
    if(window.confirm('Are you sure you want to delete this domain?')) {
      this.loading = true;

      this.domainService.delete(domain.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          result => {
            this.alertService.success('Deleted domain.');
            this.loading = false;
            this.campaign.custom_domains = this.campaign.custom_domains.filter(data => data.id != domain.id);
          },
          error => {
            this.handleSubmitError(error);
            this.loading = false;
          });
    }
  }

  getType(domain: CustomDomain) {
    return SessionTypeLabels[domain.type];
  }

  private openEditDialog() {
    this.modalService.getModal('customDomainDialog').open();
  }

  private openPhoneDialog() {
    this.modalService.getModal('customDomainPhoneDialog').open();
  }

  private openFunnelsDialog() {
    this.modalService.getModal('customDomainFunnelsDialog').open();
  }

  onSaveDomain(domain: CustomDomain) {
    if (this.selectedDomain && this.selectedDomain.id) {
      this.onDomainUpdated(domain);
      this.modalService.getModal('customDomainDialog').close();
      this.modalService.getModal('customDomainPhoneDialog').close();
      this.modalService.getModal('customDomainFunnelsDialog').close();
    }
    else {
      this.onDomainAdded(domain);
    }
  }

  onVerifyDomain(domain: CustomDomain) {
    this.onDomainUpdated(domain);
    let _this = this;
    setTimeout(() => {
      let domains = this.campaign.custom_domains;
      this.campaign.custom_domains = [];
      this.campaign.custom_domains = domains
    }, 301000)
  }

  private onDomainUpdated(domain: CustomDomain) {
    // replace existing domain
    for (let i = 0; i < this.campaign.custom_domains.length; i++) {
      if (this.campaign.custom_domains[i].id === domain.id) {
        this.campaign.custom_domains[i] = domain;
        break;
      }
    }
  }

  private onDomainAdded(domain: CustomDomain) {
    // append new domain
    this.campaign.custom_domains.push(domain);
  }

  getFormData() {
    let domainIds = [];

    this.campaign.custom_domains.forEach((domain: CustomDomain) => {
      domainIds.push(domain.id);
    });

    return domainIds;
  }

  canBeDeleted(domain: CustomDomain) {
    if (domain.verified) {
      let verified = moment(domain.verified);
      let now = moment();
      let duration = moment.duration(now.diff(verified))
      return duration.get('minutes') >= 5;
    }

    return true;
  }

  onLinkSaveComplete(deploymentLink: DeploymentLink) {
    this.onLinkSave.emit(deploymentLink);
  }
}
