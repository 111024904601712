import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OffersAbstractComponent } from './offers-abstract.component';
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  PusherService,
  StepTemplateService,
  UserService
} from '../../_services';
import { OfferTypes } from '../../../app/_models';

@Component({
  selector: 'survey-steps',
  templateUrl: './../steps.component.html',
  styleUrls: ['./../steps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyStepsComponent extends OffersAbstractComponent implements OnInit {
  stepsTitle = 'Survey';
  offerType = OfferTypes.Survey;
  isSurvey = true;
  isOffersPage: boolean = false;
  showViewButton: boolean = true;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected funnelStepService: FunnelStepService,
    protected stepService: StepTemplateService,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected pusherService: PusherService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    protected route: ActivatedRoute
  ) {
    super(
      router,
      location,
      alertService,
      funnelService,
      funnelStepService,
      stepService,
      campaignService,
      userService,
      pusherService,
      loader,
      ngxSmartModalService,
      route
    );
  }

  saveTemplate(event: { use_existing_template: boolean }): void {
    this.ngxSmartModalService.getModal('templatePopup').close();
    this.router.navigate([
      'steps',
      'survey',
      'category',
      this.offerType,
      'useExisting',
      event.use_existing_template,
    ]);
  }
}
